// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-features-accounting-js": () => import("./../../../src/pages/features/accounting.js" /* webpackChunkName: "component---src-pages-features-accounting-js" */),
  "component---src-pages-features-integration-js": () => import("./../../../src/pages/features/integration.js" /* webpackChunkName: "component---src-pages-features-integration-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-security-js": () => import("./../../../src/pages/features/security.js" /* webpackChunkName: "component---src-pages-features-security-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

